<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>{{ staticNames.name }} Static Banner</strong>
            <small>Form</small>
          </div>
          <b-form v-on:submit.prevent.self="submitStaticBanner">
            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="logo">Banner Image URL</label>
                  <b-form-input
                    type="text"
                    required
                    v-model="staticBanner.TargetUrl"
                    id="bannerURL"
                    placeholder="Banner URL"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="logo">&nbsp;</label>
                  <v-select
                    v-model="selectedCountry"
                    :options="getActiveCountriesList"
                    :clearable="false"
                    :close-on-select="true"
                    :reduce="(country) => country.CountryID"
                    label="CountryName"
                    @input="onChangeCinemasDropdwon($event)"
                    class="v-select-cust CustomVSelect Movies-V-select"
                    placeholder="Search Country Name..."
                  >
                    <template slot="option" slot-scope="option">{{
                      option.CountryName
                    }}</template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group>
                  <label for="valueType">Type</label>
                  <b-form-select
                    required
                    v-model="staticBanner.BannerType"
                    :plain="true"
                    :options="['Home', 'Movie', 'Static']"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="Title">Title</label>
                  <b-form-input
                    type="text"
                    required
                    v-model="staticBanner.Title"
                    id="Title"
                    placeholder="Title"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group>
                  <label for="SubTitle">Sub Text</label>
                  <b-form-input
                    type="text"
                    required
                    v-model="staticBanner.SubTitle"
                    id="SubTitle"
                    placeholder="Sub Text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="valueType">Page Name</label>
                  <b-form-select
                    required
                    v-model="staticBanner.PageName"
                    :plain="true"
                    :options="[
                      'allCinema',
                      'singleCinema',
                      'referral',
                      'external',
                    ]"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="from-date">From Date :</label>
                  <b-form-input
                    type="date"
                    v-model="staticBanner.FromDate"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="to-date">Expiry Date :</label>
                  <b-form-input
                    type="date"
                    v-model="staticBanner.ToDate"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="logo">
                    Banner Image File
                    <br />Size: 1920*575
                    <!-- 1280*720 -->
                  </label>
                  <b-form-file
                    ref="fileinput"
                    :required="staticBanner.BannerID ? false : true"
                    v-on:change="uploadBanner($event.target, 'StaticBanner')"
                    accept="image/jpeg, image/png, image/gif"
                    :plain="true"
                  ></b-form-file>
                  <input type="hidden" v-model="staticBanner.Image" />
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group>
                  <label for="logo">
                    Banner Image File
                    <br />Size: 612*408
                    <!-- 1280*720 -->
                  </label>
                  <b-form-file
                    ref="fileinput1"
                    :required="staticBanner.BannerID ? false : true"
                    v-on:change="uploadBanner($event.target, 'MobileBanner')"
                    accept="image/jpeg, image/png, image/gif"
                    :plain="true"
                  ></b-form-file>
                  <input type="hidden" v-model="staticBanner.Mobile" />
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group>
                  <label for="logo">Status</label>
                  <br />
                  <c-switch
                    class="mx-1"
                    color="primary"
                    v-model="staticBanner.BannerIsActive"
                    variant="3d"
                    label
                    v-bind="{ dataOn: '\u2713', dataOff: '\u2715' }"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" id="preview" class="mb-3">
                <img
                  v-if="staticBanner.Image != ''"
                  :src="BaseURL + staticBanner.Image"
                  class="img-fluid"
                />
              </b-col>
              <b-col sm="12" id="preview" class="mb-3">
                <img
                  v-if="staticBanner.MobileBanner != ''"
                  :src="BaseURL + staticBanner.MobileBanner"
                  class="img-fluid"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                <b-button type="submit" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i>
                  {{ staticNames.name }}
                </b-button>
              </b-col>
              <b-col sm="4">
                <b-button
                  @click="resetStaticBanner"
                  size="sm"
                  variant="success"
                >
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MovieService from "@/services/MovieService";
import MasterService from "@/services/MasterService";
import UploadService from "@/services/UploadService";

import { Switch as cSwitch } from "@coreui/vue";

export default {
  data() {
    return {
      BaseURL: "",
      getActiveCountriesList: [],
      selectedCountry: null,
      staticNames: {
        name: "Add",
      },
      staticBanner: {
        Title: "",
        BannerType: "Home",
        SubTitle: "",
        CountryID: null,
        BannerID: null,
        Image: "",
        MobileBanner: "",
        TargetUrl: "",
        BannerIsActive: true,
        Flag: 1,
        PageName: "",
        FromDate: "",
        ToDate: "",
      },
    };
  },
  components: {
    cSwitch,
  },
  computed: {},
  watch: {},
  beforeMount() {
    const { bannerId, countryId } = this.$route.query;
    // console.log("getBannersDetails", bannerId);
    if (bannerId && countryId) {
      this.staticBanner.BannerID = bannerId;
      this.staticBanner.Flag = 2;
      this.staticNames.name = "Update";
      this.staticBanner.CountryID = countryId;
      this.getBannersDetails(bannerId, countryId);
    } else {
      this.getActiveCountryList(null);
    }
  },
  mounted: function () {},
  methods: {
    onChangeCinemasDropdwon: function (event) {
      console.log("event :", event);
      this.staticBanner.CountryID = event.CountryID;
    },
    getActiveCountryList(id) {
      MasterService.getActiveCountries()
        .then((response) => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            this.getActiveCountriesList = data.CountryList;
            console.log("id :", id);
            if (id) {
              data.CountryList.map((x) => {
                if (id === String(x.CountryID)) {
                  this.selectedCountry = x;
                }
              });
            }
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getBannersDetails: function (BannerID, CountryID) {
      let payload = {
        BannerID,
        CountryID,
      };

      MasterService.getBannerByID(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status && data.BannerList.length > 0) {
            console.log("data.BannerList :", data.BannerList);
            this.staticBanner.Flag = 2;
            this.staticBanner.Image = data.BannerList[0].Image;
            this.staticBanner.MobileBanner = data.BannerList[0].MobileBanner;
            this.staticBanner.TargetUrl = data.BannerList[0].TargetUrl;
            this.staticBanner.Title = data.BannerList[0].Title;
            this.staticBanner.SubTitle = data.BannerList[0].SubTitle;
            this.staticBanner.BannerType = data.BannerList[0].BannerType;

            // this.staticBanner.CountryID = data.BannerList[0].CountryID;
            this.BaseURL = data.BaseURL;
            this.getActiveCountryList(this.staticBanner.CountryID);
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          console.log("Catch on Banner-getBannerByID Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    submitStaticBanner: function () {
      let payload = {
        title: "Confirmation",
        message: "Please re-check the data before submit",
      };
      this.showSubmitConfirmation(payload);
    },
    submitFinalData: function () {
      this.closeModal("ModalConfirmationOnSubmit");
      console.log("this.staticBanner :", this.staticBanner);

      MasterService.addBanner(this.staticBanner)
        .then((response) => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";

          if (data.Status) {
            this.resetStaticBanner();
            this.$refs.fileinput.reset();
            this.$router.push({
              path: "/banners/bannerlist",
            });
          }
        })
        .catch((error) => {
          console.log("Catch on Banner-getBannerByID Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    uploadBanner: function (image, name) {
      let refs = this.$refs;
      var self = this;
      var _URL = window.URL || window.webkitURL;
      var file, img;
      if ((file = image.files[0])) {
        img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = async function () {
          // 1280*720
          // 1920*575
          if (
            (img.height != 575 || img.width != 1920) &&
            name == "StaticBanner"
          ) {
            // if (false) {
            self.$refs["fileinput"].reset();
            self.showMessageSnackbar("Please upload 1920*575 image");
          } else if (
            (img.height != 408 || img.width != 612) &&
            name == "MobileBanner"
          ) {
            self.$refs["fileinput1"].reset();
            self.showMessageSnackbar("Please upload 612*408 image");
          } else {
            var formdata = new FormData();
            formdata.append("assets/images/movies/banners", image.files[0]);
            console.log("formdata: ", formdata);
            UploadService.uploadFiles(formdata)
              .then((response) => {
                response.data.Message
                  ? self.showMessageSnackbar(response.data.Message)
                  : "";

                if (response.data.Status) {
                  if (name == "StaticBanner") {
                    self.staticBanner.Image = response.data.Path;
                  } else if (name == "MobileBanner") {
                    self.staticBanner.MobileBanner = response.data.Path;
                  }
                  self.BaseURL = response.data.BaseURL;
                }
              })
              .catch((error) => {
                console.log("Catch on AddStaticImages-onUpload Error: ", error);
                self.showMessageSnackbar(
                  "Oops! Something went wrong. kindly contact your administrator"
                );
              });
          }
        };
      }
    },
    resetStaticBanner: function () {
      if (this.staticBanner.BannerID) {
        window.location.replace(
          window.location.origin + "/" + window.location.hash.split("?")[0]
        );
      }
      this.staticBanner = {
        Title: "",
        SubTitle: "",
        CountryID: null,
        BannerType: "Home",
        BannerID: null,
        Image: "",
        MobileBanner: "",
        TargetUrl: "",
        BannerIsActive: true,
        Flag: 1,
      };
      this.BaseURL = "";
      this.$refs["fileinput"].reset();
    },
    // Show Confirmmation Dialog
    handleModalOkay: function (evt) {
      // // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function (payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function (ref) {
      this.$refs[ref].show();
    },
    closeModal: function (ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function (Message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = Message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
  updated() {},
};
</script>

<style></style>
